const feedback = [
  {
    id: 1,
    name: 'Ірина',
    stars: new URL('../images/Group_43.svg', import.meta.url),
    description:
      "Я вчора закінчила третій тиждень. Тренування дійсно дуже круті. Я проходила багато різних марафонів і є з чим порівняти. В захваті від того, що кожен раз іду тренуватись із задоволенням, а не з думкою «та за шо?!». Бо знаю що тренування буде не на виснаження, а з любов'ю до тіла. Дякую",
  },
  {
    id: 2,
    name: 'Анастасія',
    stars: new URL('../images/Group_43.svg', import.meta.url),
    description:
      'Анічка, доброго дня. Дуже круті вправи. Якраз і біль пройшла в спині, і тепло таке приємне. Те, що треба постійно робити, щоб гарно себе почувати. Дякую🌸',
  },
  {
    id: 3,
    name: 'Ауріка',
    stars: new URL('../images/Group_43.svg', import.meta.url),
    description:
      'Аня, я завершила курс і хочу сказати вам величезне дякую! Я давно шукала курс зі схожим підходом, де основа це не бездумне качання дупки, a усвідомлений спокійний підхід, де можна чітко зрозуміти що, і головне, для чого ми робимо! Стільки планок я, мабуть, в житті ще не робила, але це тільки закохало мене в них ще більше!І найголовніше, всі вправи направлені на оздоровлення! Різноманітні хвилі, вправи на мобільність хребта, тазо-кульшових суглобів реально допомагають почувати себе набагато краще, а найголовніше, що я ці знання зможу передавати далі. Тому для мене це був цінний досвід, який нарешті я отримала українською мовою! Бо більшість тренерів, які мають схожий підхід, в яких я проходила навчання у свій час, були росіяни, з якими працювати вже не хочеться. Тому ще раз дякую і бажаю натхнення і розвитку🌸',
  },
  {
    id: 4,
    name: 'Юлія',
    stars: new URL('../images/Group_43.svg', import.meta.url),
    description:
      "Привіт. Аня, спершу хочу подякувати за курс. Часто читаю у тих, хто займається, що тіло просить тренувань і ніяк цей вислів не сприймався) Як це просить?))) Але, навіть з переривами та боротьбою з лінню (звісно вона була), за цей час з'явилось те саме відчуття, коли ХОЧЕТЬСЯ позайматися. Тіло дійсно просить позгинатись. Обожнюю відчувати м'язи після тренувань і це майже після кожного разу. Тридцять хвилин, але купа користі для себе. Обов'язково закінчу всі тренування та планую продовжувати. Додам, що ти дуже мотивуєш займатися своєю формою. Виглядаєш чудово!",
  },
  {
    id: 5,
    name: 'Ольга',
    stars: new URL('../images/Group_43.svg', import.meta.url),
    description:
      "Я фінішувала! Дякую за такий потужний курс, я за ці 20 тренувань однозначно стала сильніша, а головне здоровішим стало моє тіло! Після сидіння за ноутбуком вже не болить шия, спина! Також бачу, що м'язи стали твердіші, відповідно і відображення в дзеркалі змінилося! І далі буду продовжувати обов'язково!",
  },
];

export { feedback };
